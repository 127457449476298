import styled from "styled-components"
import { Link } from "gatsby"

const FooterWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.color.border.light};
  padding: ${props => props.theme.spaces.xl} 0 0 0;
  color: ${props => props.theme.color.font.light};
  font-size: 15px;
  font-weight: 400;
`

const FooterRow = styled.div`
  display: flex;
  justify-content: center;
  margin: ${props => props.theme.spaces.stack.xl};

  @media (max-width: ${props => props.theme.media.phone}) {
    flex-direction: column;
  }
`

const FooterContentBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1.7;
  min-width: 100px;
  margin: ${props => props.theme.spaces.stack.m};
`

const FooterLinkStyle = styled(Link)`
  color: ${props => props.theme.color.font.light};
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  transition: color 0.3s;

  :hover {
    color: ${props => props.theme.color.font.regular};
  }
`

const FooterSocialIconStyle = styled.a`
  color: ${props => props.theme.color.font.light};
  transition: color 0.3s;

  :visited {
    color: ${props => props.theme.color.font.light};
  }
`

const TwitterStyled = styled(FooterSocialIconStyle)`
  :hover {
    color: ${props => props.theme.color.social.twitter};
  }
`

const LinkedInStyled = styled(FooterSocialIconStyle)`
  :hover {
    color: ${props => props.theme.color.social.linkedIn};
  }
`

const FooterCopyright = styled.div`
  color: ${props => props.theme.color.font.veryLight};
  font-size: 13px;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
`

export {
  FooterWrapper,
  FooterCopyright,
  FooterContentBox,
  FooterRow,
  FooterLinkStyle,
  FooterSocialIconStyle,
  TwitterStyled,
  LinkedInStyled,
}
