import React from "react"

import Twitter from "../../svgs/twitter-brands.svg"
import LinkedIn from "../../svgs/linkedin-in-brands.svg"

import { TwitterStyled, LinkedInStyled } from "./styled-footer"

const socialStyles = {
  height: "30px",
  width: "30px",
}
function FooterSocialIcon(props) {
  return (
    (props.network === "twitter" && (
      <TwitterStyled href="https://twitter.com/into_numbers">
        <Twitter style={socialStyles} />
      </TwitterStyled>
    )) ||
    (props.network === "linkedin" && (
      <LinkedInStyled href="https://www.linkedin.com/in/just-peter-intonumbers/">
        <LinkedIn style={socialStyles} />
      </LinkedInStyled>
    ))
  )
}

export default FooterSocialIcon
