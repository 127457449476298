import palette from "./palette"

const color = {
  palette: { ...palette },
  white: palette.white,
  primary: palette.blueVivid5,
  secundary: palette.greenVivid5,
  neutral: palette.grey5,
  font: {
    white: palette.white,
    darkWhite: palette.greyWarm1,
    veryLight: palette.grey2,
    light: palette.grey5,
    regular: palette.grey7,
    dark: palette.grey8,
    veryDark: palette.grey9,
  },
  border: {
    light: palette.greyBlue2,
    regular: palette.greyBlue3,
    dark: palette.greyBlue8,
  },
  shadow: {
    light: palette.grey2,
    medium: palette.grey4,
    dark: palette.grey5,
  },
  social: {
    twitter: "#1da1f2",
    linkedIn: "#0077B5",
  },
  background: {
    darkBlue: palette.greyBlue9,
  },
  intoNumbers: {
    lightBlue: "#a3d2ea",
    darkBlue: "#0094cc",
  },
}

export default color
