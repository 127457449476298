import styled from "styled-components"

const ContentRow = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.2rem;
  padding-top: 0;
`

export default ContentRow
