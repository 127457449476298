import React from "react"
import { ThemeProvider } from "styled-components"

import { Location } from "@reach/router"

import theme from "./theme"
import GlobalStyle from "./GlobalStyle"

import Header from "../components/Header"
import Footer from "../components/Footer"

function getHeadlines(location) {
  const myLocation = location.pathname

  let headline = "Data Science"
  let subHeadline = "Ihre Daten als strategisches Gut"

  if (myLocation === "/") {
    headline = "Data Science"
    subHeadline = "Ihre Daten als strategisches Gut"
  } else if (myLocation === "/kontakt") {
    headline = "Kontakt"
    subHeadline = "Wir freuen uns auf Ihre Anfrage"
  } else if (myLocation === "/impressum") {
    headline = "Impressum"
    subHeadline = "vom: 22.05.2019"
  } else if (myLocation === "/datenschutz") {
    headline = "Datenschutz"
    subHeadline = "vom: 22.05.2019"
  }

  return { headline: headline, subHeadline: subHeadline }
}

function Layout({ children }) {
  return (
    <Location>
      {({ location }) => (
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <div
              style={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Header
                headline={getHeadlines(location).headline}
                subHeadline={getHeadlines(location).subHeadline}
              />

              <main style={{ flex: "auto" }}>{children}</main>
              <Footer />
            </div>
          </>
        </ThemeProvider>
      )}
    </Location>
  )
}
export default Layout
