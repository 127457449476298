import styled from "styled-components"

import { Link } from "gatsby"

const TopMenuBar = styled.div`
  padding: ${props => props.theme.spaces.stack.xxs};

  ${props =>
    props.colored &&
    `
    background: ${props.theme.color.white};
    box-shadow: ${props.theme.shadow.topMenuShadow};
  `}

  transition: background 0.5s, box-shadow 0.5s;
  transition-timing-function: ease-out;
`

const TopMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const TopMenuLink = styled(Link)`
  color: ${props => props.theme.color.font.white};
  padding: ${props => props.theme.spaces.xxs} 0;
  text-decoration: none;
  margin-left: ${props => props.theme.spaces.l};
  user-select: none;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.4s, color 0.3s;
  transition-timing-function: ease-out;

  :hover {
    border-bottom: 2px solid ${props => props.theme.color.font.darkWhite};
    color: ${props => props.theme.color.font.darkWhite};
  }

  ${props =>
    props.colored &&
    `
    color: ${props.theme.color.font.regular};
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.4s, color 0.3s;
    transition-timing-function: ease-out;

    :hover {
    border-bottom: 2px solid ${props.theme.color.font.light};
    color: ${props.theme.color.font.light};
  }
  `}
`

const TopMenuLogo = styled.div`
  margin-top: ${props => props.theme.spaces.s};
  user-select: none;
`

const TopMenuLinkBar = styled.div`
  margin-top: ${props => props.theme.spaces.m};
`

export { TopMenuLogo, TopMenuBar, TopMenuWrapper, TopMenuLink, TopMenuLinkBar }
