const palette = {
  white: "#FFF",

  grey0: "#F7F7F7",
  grey1: "#E1E1E1",
  grey2: "#CFCFCF",
  grey3: "#B1B1B1",
  grey4: "#9E9E9E",
  grey5: "#7E7E7E",
  grey6: "#626262",
  grey7: "#515151",
  grey8: "#3B3B3B",
  grey9: "#222222",

  greyBlue0: "#F0F4F8",
  greyBlue1: "#D9E2EC",
  greyBlue2: "#BCCCDC",
  greyBlue3: "#9FB3C8",
  greyBlue4: "#829AB1",
  greyBlue5: "#627D98",
  greyBlue6: "#486581",
  greyBlue7: "#334E68",
  greyBlue8: "#243B53",
  greyBlue9: "#102A43",

  greyCool0: "#F5F7FA",
  greyCool1: "#E4E7EB",
  greyCool2: "#9AA5B1",
  greyCool3: "#9AA5B1",
  greyCool4: "#7B8794",
  greyCool5: "#616E7C",
  greyCool6: "#52606D",
  greyCool7: "#3E4C59",
  greyCool8: "#323F4B",
  greyCool9: "#1F2933",

  greyWarm0: "#FAF9F7",
  greyWarm1: "#E8E6E1",
  greyWarm2: "#D3CEC4",
  greyWarm3: "#B8B2A7",
  greyWarm4: "#A39E93",
  greyWarm5: "#857F72",
  greyWarm6: "#625D52",
  greyWarm7: "#504A40",
  greyWarm8: "#423D33",
  greyWarm9: "#27241D",

  red0: "#FFEEEE",
  red1: "#FACDCD",
  red2: "#F29B9B",
  red3: "#E66A6A",
  red4: "#D64545",
  red5: "#BA2525",
  red6: "#A61B1B",
  red7: "#911111",
  red8: "#780A0A",
  red9: "#610404",

  redVivid0: "#FFE3E3",
  redVivid1: "#FFBDBD",
  redVivid2: "#FF9B9B",
  redVivid3: "#F86A6A",
  redVivid4: "#EF4E4E",
  redVivid5: "#E12D39",
  redVivid6: "#CF1124",
  redVivid7: "#AB091E",
  redVivid8: "#8A041A",
  redVivid9: "#610316",

  orange0: "#FFEFE6",
  orange1: "#FFD3BA",
  orange2: "#FAB38B",
  orange3: "#EF8E58",
  orange4: "#E67635",
  orange5: "#C65D21",
  orange6: "#AB4E19",
  orange7: "#8C3D10",
  orange8: "#77340D",
  orange9: "#572508",

  orangeVivid0: "#FFE8D9",
  orangeVivid1: "#FFD0B5",
  orangeVivid2: "#FFB088",
  orangeVivid3: "#FF9466",
  orangeVivid4: "#F9703E",
  orangeVivid5: "#F35627",
  orangeVivid6: "#DE3A11",
  orangeVivid7: "#C52707",
  orangeVivid8: "#AD1D07",
  orangeVivid9: "#841003",

  yellow0: "#FFFAEB",
  yellow1: "#FCEFC7",
  yellow2: "#F8E3A3",
  yellow3: "#F9DA8B",
  yellow4: "#F7D070",
  yellow5: "#E9B949",
  yellow6: "#C99A2E",
  yellow7: "#A27C1A",
  yellow8: "#7C5E10",
  yellow9: "#513C06",

  yellowVivid0: "#FFFBEA",
  yellowVivid1: "#FFF3C4",
  yellowVivid2: "#FCE588",
  yellowVivid3: "#FADB5F",
  yellowVivid4: "#F7C948",
  yellowVivid5: "#F0B429",
  yellowVivid6: "#DE911D",
  yellowVivid7: "#CB6E17",
  yellowVivid8: "#B44D12",
  yellowVivid9: "#8D2B0B",

  limeGreen0: "#F2FDE0",
  limeGreen1: "#E2F7C2",
  limeGreen2: "#C7EA8F",
  limeGreen3: "#ABDB5E",
  limeGreen4: "#94C843",
  limeGreen5: "#7BB026",
  limeGreen6: "#63921A",
  limeGreen7: "#507712",
  limeGreen8: "#42600C",
  limeGreen9: "#2B4005",

  limeGreenVivid0: "#F8FFED",
  limeGreenVivid1: "#E6FFBF",
  limeGreenVivid2: "#CAFF84",
  limeGreenVivid3: "#AFF75C",
  limeGreenVivid4: "#8DED2D",
  limeGreenVivid5: "#6CD410",
  limeGreenVivid6: "#5CB70B",
  limeGreenVivid7: "#399709",
  limeGreenVivid8: "#2E7B06",
  limeGreenVivid9: "#1E5303",

  green0: "#E3F9E5",
  green1: "#C1EAC5",
  green2: "#A3D9A5",
  green3: "#7BC47F",
  green4: "#57AE5B",
  green5: "#3F9142",
  green6: "#2F8132",
  green7: "#207227",
  green8: "#0E5814",
  green9: "#05400A",

  greenVivid0: "#E3F9E5",
  greenVivid1: "#C1F2C7",
  greenVivid2: "#91E697",
  greenVivid3: "#51CA58",
  greenVivid4: "#31B237",
  greenVivid5: "#18981D",
  greenVivid6: "#0F8613",
  greenVivid7: "#0E7817",
  greenVivid8: "#07600E",
  greenVivid9: "#014807",

  teal0: "#EFFCF6",
  teal1: "#C6F7E2",
  teal2: "#8EEDC7",
  teal3: "#65D6AD",
  teal4: "#3EBD93",
  teal5: "#27AB83",
  teal6: "#199473",
  teal7: "#147D64",
  teal8: "#0C6B58",
  teal9: "#014D40",

  tealVivid0: "#F0FCF9",
  tealVivid1: "#C6F7E9",
  tealVivid2: "#8EEDD1",
  tealVivid3: "#5FE3C0",
  tealVivid4: "#2DCCA7",
  tealVivid5: "#17B897",
  tealVivid6: "#079A82",
  tealVivid7: "#048271",
  tealVivid8: "#016457",
  tealVivid9: "#004440",

  cyan0: "#E0FCFF",
  cyan1: "#BEF8FD",
  cyan2: "#87EAF2",
  cyan3: "#54D1DB",
  cyan4: "#38BEC9",
  cyan5: "#2CB1BC",
  cyan6: "#14919B",
  cyan7: "#0E7C86",
  cyan8: "#0A6C74",
  cyan9: "#044E54",

  cyanVivid0: "#E1FCF8",
  cyanVivid1: "#C1FEF6",
  cyanVivid2: "#92FDF2",
  cyanVivid3: "#62F4EB",
  cyanVivid4: "#3AE7E1",
  cyanVivid5: "#1CD4D4",
  cyanVivid6: "#0FB5BA",
  cyanVivid7: "#099AA4",
  cyanVivid8: "#07818F",
  cyanVivid9: "#05606E",

  lightBlue0: "#EBF8FF",
  lightBlue1: "#D1EEFC",
  lightBlue2: "#A7D8F0",
  lightBlue3: "#7CC1E4",
  lightBlue4: "#55AAD4",
  lightBlue5: "#3994C1",
  lightBlue6: "#2D83AE",
  lightBlue7: "#1D6F98",
  lightBlue8: "#166086",
  lightBlue9: "#0B4F71",

  lightBlueVivid0: "#E3F8FF",
  lightBlueVivid1: "#B3ECFF",
  lightBlueVivid2: "#81DEFD",
  lightBlueVivid3: "#5ED0FA",
  lightBlueVivid4: "#40C3F7",
  lightBlueVivid5: "#2BB0ED",
  lightBlueVivid6: "#1992D4",
  lightBlueVivid7: "#127FBF",
  lightBlueVivid8: "#0B69A3",
  lightBlueVivid9: "#035388",

  blue0: "#DCEEFB",
  blue1: "#B6E0FE",
  blue2: "#84C5F4",
  blue3: "#62B0E8",
  blue4: "#4098D7",
  blue5: "#2680C2",
  blue6: "#186FAF",
  blue7: "#0F609B",
  blue8: "#0A558C",
  blue9: "#003E6B",

  blueVivid0: "#E6F6FF",
  blueVivid1: "#BAE3FF",
  blueVivid2: "#7CC4FA",
  blueVivid3: "#47A3F3",
  blueVivid4: "#2186EB",
  blueVivid5: "#0967D2",
  blueVivid6: "#0552B5",
  blueVivid7: "#03449E",
  blueVivid8: "#01337D",
  blueVivid9: "#002159",

  indigo0: "#E0E8F9",
  indigo1: "#BED0F7",
  indigo2: "#98AEEB",
  indigo3: "#7B93DB",
  indigo4: "#647ACB",
  indigo5: "#4C63B6",
  indigo6: "#4055A8",
  indigo7: "#35469C",
  indigo8: "#2D3A8C",
  indigo9: "#19216C",

  indigoVivid0: "#D9E8FF",
  indigoVivid1: "#B0D0FF",
  indigoVivid2: "#88B1FC",
  indigoVivid3: "#5E8AEE",
  indigoVivid4: "#3A66DB",
  indigoVivid5: "#2251CC",
  indigoVivid6: "#1D3DBF",
  indigoVivid7: "#132DAD",
  indigoVivid8: "#0B1D96",
  indigoVivid9: "#061178",

  purple0: "#EAE2F8",
  purple1: "#CFBCF2",
  purple2: "#A081D9",
  purple3: "#8662C7",
  purple4: "#724BB7",
  purple5: "#653CAD",
  purple6: "#51279B",
  purple7: "#421987",
  purple8: "#34126F",
  purple9: "#240754",

  purpleVivid0: "#F2EBFE",
  purpleVivid1: "#DAC4FF",
  purpleVivid2: "#B990FF",
  purpleVivid3: "#A368FC",
  purpleVivid4: "#9446ED",
  purpleVivid5: "#8719E0",
  purpleVivid6: "#7A0ECC",
  purpleVivid7: "#690CB0",
  purpleVivid8: "#580A94",
  purpleVivid9: "#44056E",

  magenta0: "#F5E1F7",
  magenta1: "#ECBDF2",
  magenta2: "#CE80D9",
  magenta3: "#BB61C7",
  magenta4: "#AD4BB8",
  magenta5: "#A23DAD",
  magenta6: "#90279C",
  magenta7: "#7C1A87",
  magenta8: "#671270",
  magenta9: "#4E0754",

  magentaVivid0: "#FDEBFF",
  magentaVivid1: "#F8C4FE",
  magentaVivid2: "#F48FFF",
  magentaVivid3: "#F368FC",
  magentaVivid4: "#ED47ED",
  magentaVivid5: "#E019D0",
  magentaVivid6: "#CB10B8",
  magentaVivid7: "#B30BA3",
  magentaVivid8: "#960888",
  magentaVivid9: "#6E0560",

  pink0: "#FFE0F0",
  pink1: "#FAB8D9",
  pink2: "#F191C1",
  pink3: "#E668A7",
  pink4: "#DA4A91",
  pink5: "#C42D78",
  pink6: "#AD2167",
  pink7: "#9B1B5A",
  pink8: "#781244",
  pink9: "#5C0B33",

  pinkVivid0: "#FFE3EC",
  pinkVivid1: "#FFB8D2",
  pinkVivid2: "#FF8CBA",
  pinkVivid3: "#F364A2",
  pinkVivid4: "#E8368F",
  pinkVivid5: "#DA127D",
  pinkVivid6: "#BC0A6F",
  pinkVivid7: "#A30664",
  pinkVivid8: "#870557",
  pinkVivid9: "#620042",
}

export default palette
