import styled from "styled-components"

const HeaderTitle = styled.h2`
  font-size: 3em;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0.67em 0;
  color: ${props => props.theme.color.font.white};
  margin-top: 110px;
  margin-bottom: 5px;
`

const HeaderSubTitle = styled.div`
  font-size: 1.2em;
  font-weight: 400;
  color: ${props => props.theme.color.font.white};
  margin-bottom: -50px;
`

export { HeaderTitle, HeaderSubTitle }
