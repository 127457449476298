import React from "react"

import Particles from "react-particles-js"

import useTheme from "../../layout/theme/useTheme"
import ContentRow from "../ContentRow"
import WaveImage from "../../svgs/wave1.svg"
import TopMenu from "../TopMenu"

import { HeaderTitle, HeaderSubTitle } from "./styled-header"

const HeaderHeight = "290px"

function Header({ headline, subHeadline }) {
  const { color } = useTheme()
  return (
    <>
      <header>
        <Particles
          height={HeaderHeight}
          params={{
            particles: {
              color: {
                value: color.white,
              },
              shape: {
                type: "circle",
                stroke: {
                  color: "transparent",
                  width: 1,
                },
              },
              number: {
                value: 100,
              },
              size: {
                value: 2,
              },
              move: {
                speed: 0.3,
                out_mode: "bounce",
                bounce: false,
              },
              opacity: {
                value: 0.1,
                random: true,
                anim: {
                  enable: true,
                  speed: 0.1,
                },
              },
              line_linked: {
                color: color.white,
                opacity: 0.15,
              },
            },
            interactivity: {
              events: {
                onclick: {
                  enable: true,
                  mode: "repulse",
                },
                modes: {
                  repulse: {
                    distance: 1,
                  },
                },
                resize: true,
              },
            },
            retina_detect: true,
          }}
          style={{
            background: "transparent",
            position: "absolute",
            width: "100%",
            top: 0,
            right: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: 0,
            left: 0,
            height: HeaderHeight,
            background: color.background.darkBlue,
            zIndex: -1,
          }}
        />
        <div
          style={{
            position: "fixed",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        >
          <TopMenu style={{ position: "fixed", zIndex: 1 }} />
        </div>
        <ContentRow>
          <HeaderTitle>{headline}</HeaderTitle>
          <HeaderSubTitle>{subHeadline}</HeaderSubTitle>
        </ContentRow>
        <WaveImage style={{ height: "150px", width: "100%" }} />
      </header>
    </>
  )
}

export default Header
