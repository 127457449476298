import color from "../colors"
import { transparentize } from "polished"

const shadow = {
  mainShadow: `0 1px 3px ${transparentize(0.2, color.shadow.medium)},
    0 1px 2px ${transparentize(0.1, color.shadow.dark)}
    `,
  topMenuShadow: `0 1px 2px ${transparentize(0.7, color.shadow.medium)},
    0 1px 1px ${transparentize(0.5, color.shadow.dark)}
    `,
  glowShadow: `0 2px 40px ${transparentize(
    0.5,
    color.shadow.medium
  )}, 0 1px 2px ${transparentize(0.8, color.shadow.medium)}`,
}

export default shadow
