import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    html {
        font-family: "Inter UI", sans-serif;
        -ms-text-size-adjust: 100%;
         -webkit-text-size-adjust: 100%;
    }
    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    main{
        color: ${props => props.theme.color.font.regular};
        line-height: 1.5;
    }
    h1{
        font-family: "Inter UI", sans-serif;
        font-size: 2em;
        margin: 0.67em 0;
    }
    h2{
        font-family: "Inter UI", sans-serif;
        font-size: 1.5em;
        margin: 0.67em 0;
    }
`

export default GlobalStyle
