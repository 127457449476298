import color from "./colors"
import media from "./media"
import shadow from "./shadows"
import spaces from "./spaces"

const theme = {
  color: color,
  media: media,
  shadow: shadow,
  spaces: spaces,
}

export default theme
