import { Link, StaticQuery, graphql } from "gatsby"
import React, { useState, useLayoutEffect } from "react"
import Img from "gatsby-image"

import ContentRow from "../ContentRow"

import useTheme from "../../layout/theme/useTheme"

import {
  TopMenuBar,
  TopMenuLogo,
  TopMenuLinkBar,
  TopMenuWrapper,
  TopMenuLink,
} from "./styled-top-menu"

const Logo = props => (
  <StaticQuery
    query={graphql`
      query {
        intoNumbersLogo: file(relativePath: { eq: "into-numbers-logo.png" }) {
          childImageSharp {
            fluid(maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img fluid={data[props.name].childImageSharp.fluid} alt={props.alt} />
    )}
  />
)

function TopMenu() {
  const [coloredMenu, setColoredMenu] = useState(false)

  const handleScroll = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop
    setColoredMenu(top !== 0)
  }

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const { color } = useTheme()

  const menuColoredStyle = {
    borderBottom: `${color.font.regular} 2px solid`,
  }

  const menuUncoloredStyle = {
    borderBottom: `${color.font.white} 2px solid`,
  }
  return (
    <TopMenuBar colored={coloredMenu}>
      <ContentRow>
        <TopMenuWrapper>
          <TopMenuLogo>
            <Link to="/">
              <div style={{ width: "60px", marginBottom: "6px" }}>
                <Logo name={"intoNumbersLogo"} alt={"Into Numbers Logo"} />
              </div>
            </Link>
          </TopMenuLogo>
          <TopMenuLinkBar>
            <TopMenuLink
              to="/"
              activeStyle={coloredMenu ? menuColoredStyle : menuUncoloredStyle}
              colored={coloredMenu}
            >
              Home
            </TopMenuLink>
            <TopMenuLink
              to="/kontakt"
              activeStyle={coloredMenu ? menuColoredStyle : menuUncoloredStyle}
              partiallyActive={true}
              colored={coloredMenu}
            >
              Kontakt
            </TopMenuLink>
          </TopMenuLinkBar>
        </TopMenuWrapper>
      </ContentRow>
    </TopMenuBar>
  )
}
export default TopMenu
