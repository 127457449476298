import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import useTheme from "../../layout/theme/useTheme"
import ContentRow from "../../components/ContentRow"
import FooterLink from "./FooterLink"
import FooterSocialIcon from "./FooterSocialIcon"
import {
  FooterWrapper,
  FooterContentBox,
  FooterRow,
  FooterCopyright,
} from "./styled-footer"

const Icon = (props) => (
  <StaticQuery
    query={graphql`
      query {
        intoNumbersIcon: file(relativePath: { eq: "into-numbers-icon.png" }) {
          childImageSharp {
            fluid(maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <Img fluid={data[props.name].childImageSharp.fluid} alt={props.alt} />
    )}
  />
)

function Footer() {
  const { spaces } = useTheme()
  return (
    <footer>
      <ContentRow>
        <FooterWrapper>
          <FooterRow>
            <div style={{ width: "30px", display: "block", margin: "0 auto" }}>
              <Icon name={"intoNumbersIcon"} alt={"Into Numbers Icon"} />
            </div>
          </FooterRow>
          <FooterRow
            style={{
              justifyContent: "space-evenly",
              margin: spaces.stack.s,
            }}
          >
            <FooterContentBox>
              <FooterLink to="/">Home</FooterLink>
              <FooterLink to="/kontakt" partiallyActive={true}>
                Kontakt
              </FooterLink>
            </FooterContentBox>

            <FooterContentBox>
              <div>Into Numbers Data Science GmbH</div>
              <div>Erdbergstrasse 10/67</div>
              <div>1030 Wien - &Ouml;sterreich</div>
              <div>office@intonumbers.com</div>
              <div> +43 (0)720 701010</div>
            </FooterContentBox>

            <FooterContentBox>
              <FooterLink to="/impressum" partiallyActive={true}>
                Impressum
              </FooterLink>
              <FooterLink to="/datenschutz" partiallyActive={true}>
                Datenschutz
              </FooterLink>
            </FooterContentBox>
          </FooterRow>
          <FooterRow
            style={{ justifyContent: "space-evenly", margin: spaces.stack.s }}
          >
            <FooterContentBox
              style={{
                width: "100px",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <FooterSocialIcon network="twitter" />
              <FooterSocialIcon network="linkedin" />
            </FooterContentBox>
          </FooterRow>

          <FooterRow>
            <FooterCopyright>
              Into Numbers © {new Date().getFullYear()}
            </FooterCopyright>
          </FooterRow>
        </FooterWrapper>
      </ContentRow>
    </footer>
  )
}

export default Footer
