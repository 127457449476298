const sizes = {
  tablet: "960px",
  phone: "600px",
}

const media = {
  tablet: sizes.tablet,
  phone: sizes.phone,
}

export default media
