const spaces = {
  xxs: "4px",
  xs: "8px",
  s: "12px",
  m: "16px",
  l: "24px",
  xl: "32px",
  xxl: "48px",
  xxxl: "64px",
}

const themeSpaces = {
  ...spaces,

  inset: {
    xxs: `${spaces.xxs} ${spaces.xxs} ${spaces.xxs} ${spaces.xxs}`,
    xs: `${spaces.xs} ${spaces.xs} ${spaces.xs} ${spaces.xs}`,
    s: `${spaces.s} ${spaces.s} ${spaces.s} ${spaces.s}`,
    m: `${spaces.m} ${spaces.m} ${spaces.m} ${spaces.m}`,
    l: `${spaces.l} ${spaces.l} ${spaces.l} ${spaces.l}`,
    xl: `${spaces.xl} ${spaces.xl} ${spaces.xl} ${spaces.xl}`,
    xxl: `${spaces.xxl} ${spaces.xxl} ${spaces.xxl} ${spaces.xxl}`,
    xxxl: `${spaces.xxxl} ${spaces.xxxl} ${spaces.xxxl} ${spaces.xxxl}`,
  },

  insetSquish: {
    xxs: `2px ${spaces.xxs} 2px ${spaces.xxs}`,
    xs: `${spaces.xxs} ${spaces.xs} ${spaces.xxs} ${spaces.xs}`,
    s: `6px ${spaces.s} 6px ${spaces.s}`,
    m: `${spaces.xs} ${spaces.m} ${spaces.xs} ${spaces.m}`,
    l: `${spaces.s} ${spaces.l} ${spaces.s} ${spaces.l}`,
    xl: `${spaces.m} ${spaces.xl} ${spaces.m} ${spaces.xl}`,
    xxl: `${spaces.l} ${spaces.xxl} ${spaces.l} ${spaces.xxl}`,
    xxxl: `${spaces.xl} ${spaces.xxxl} ${spaces.xl} ${spaces.xxxl}`,
  },

  insetStretch: {
    xxs: `6px ${spaces.xxs} 6px ${spaces.xxs}`,
    xs: `${spaces.s} ${spaces.xs} 6px ${spaces.s}`,
    s: `18px ${spaces.s} 18px ${spaces.s}`,
    m: `${spaces.l} ${spaces.m} ${spaces.l} ${spaces.m}`,
    l: `36px ${spaces.l} 36px ${spaces.l}`,
    xl: `${spaces.xxl} ${spaces.xl} ${spaces.xxl} ${spaces.xl}`,
    xxl: `72px ${spaces.xxl} 72px ${spaces.xxl}`,
    xxxl: `96px ${spaces.xxxl} 96px ${spaces.xxxl}`,
  },

  stack: {
    xxs: `0 0 ${spaces.xxs} 0`,
    xs: `0 0 ${spaces.xs} 0`,
    s: `0 0 ${spaces.s} 0`,
    m: `0 0 ${spaces.m} 0`,
    l: `0 0 ${spaces.l} 0`,
    xl: `0 0 ${spaces.xl} 0`,
    xxl: `0 0 ${spaces.xxl} 0`,
    xxxl: `0 0 ${spaces.xxxl} 0`,
  },

  inline: {
    xxs: `0 ${spaces.xxs} 0 0`,
    xs: `0 ${spaces.xs} 0 0`,
    s: `0 ${spaces.s} 0 0`,
    m: `0 ${spaces.m} 0 0`,
    l: `0 ${spaces.l} 0 0`,
    xl: `0 ${spaces.xl} 0 0`,
    xxl: `0 ${spaces.xxl} 0 0`,
    xxxl: `0 ${spaces.xxxl} 0 0`,
  },
}

export default {
  ...themeSpaces,
}
