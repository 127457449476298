import React from "react"
import useTheme from "../../layout/theme/useTheme"

import { FooterLinkStyle } from "./styled-footer"

function FooterLink(props) {
  const { color } = useTheme()
  return (
    <FooterLinkStyle
      to={props.to}
      partiallyActive={props.partiallyActive}
      activeStyle={{ color: color.font.regular }}
    >
      {props.children}
    </FooterLinkStyle>
  )
}

export default FooterLink
